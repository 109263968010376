import $ from 'jquery';

export function onReady() {
  if (!$("html > head > meta[name='test_page_created_at']").length) return;

  let now = window.performance ?
    performance.timeOrigin + performance.now() :
    Date.now();

  $('html > head').append(
    `<meta name='test_page_ready_at' content='${now}'>`
  );
}
